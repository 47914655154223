<template>
    <div class="main">
      <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/rules.png" alt="">
    </div>
</template>

<script>
  export default {
    name: 'diamondRules'
  }
</script>

<style scoped lang="scss">
  .main{
    padding: 70px;
  }
  img{
    width: 100%;
  }
</style>
